import React from 'react';

import { Container, Row, Col, Layout } from 'layout';
import SitemapCategory from 'components/SitemapCategory';

import sitemapProps from './data.json';

import 'styles/main.scss';

const { title, categories } = sitemapProps;

const SitemapPage = () => (
  <Layout>
    <Container fluid className="sitemap">
      <h1 className="sitemap__title">{title}</h1>
      <Row>
        <Col lg="8">
          <Row>
            {categories.map((category) => (
              <Col md="6">
                <SitemapCategory {...category} />
              </Col>
            ))}
          </Row>
        </Col>
      </Row>
    </Container>
  </Layout>
);

export default SitemapPage;

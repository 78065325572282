import React, { FC } from 'react';
import { Link } from 'gatsby';

import { SitemapCategoryProps } from './models';

const SitemapCategory: FC<SitemapCategoryProps> = ({ name, link }) => (
  <div className="sitemap-category">
    <strong className="sitemap-category__subtitle">
      <Link to={link}>{name}</Link>
    </strong>
  </div>
);

export default SitemapCategory;
